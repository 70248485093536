import * as React from "react"
import Container from "../container"
import heroBg from "../../images/bg-top.jpg"
import voucherFlag from "../../images/flag.png"
import Button from "../button"
import { FaInstagram } from "react-icons/fa"

export default function HomeVouchers() {
  return (
    <div
      id={"vouchers"}
      className={"bg-black flex items-center flex-col md:flex-row"}
    >
      <div className={"flex-1 h-full"}>
        <Container className={"flex-col py-12 items-center gap-6"}>
          <div>
            <h2 className={"text-white text-4xl uppercase"}>
              Can Tho Vouchers
            </h2>
          </div>
          <div>
            <div className={"prose text-white p-6"}>
              <h3 className={"text-white uppercase"}>
                The perfect gift for family and friends
              </h3>
            </div>
          </div>
          <div>
            <Button
              target={"_blank"}
              theme={"primary"}
              hoverTheme={"dark"}
              href={"https://www.obee.com.au/canthokitchenbar/gift-voucher/"}
            >
              Buy a Voucher
            </Button>
          </div>
        </Container>
      </div>
      <div className={"flex-1 h-full bg-maroon"}>
        <Container className={"flex-col py-12 md:py-64 items-center gap-6"}>
          <div>
            <h2 className={"text-white text-4xl uppercase"}>Contact</h2>
          </div>
          <div>
            <div className={"prose text-center text-white font-cursive p-6"}>
              <p>5/465 Oxley Dr, Runaway Bay, QLD</p>
              <p>
                <a
                  className={"text-white hover:underline"}
                  href={"tel:0755290098"}
                >
                  Ph: 07 5529 0098
                </a>
              </p>
            </div>
          </div>
        </Container>
      </div>
    </div>
  )
}
