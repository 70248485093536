import * as React from "react"
import barBg from "../../images/bg-drinks.jpg"
import Button from "../button"
import useMenusContent from "../../hooks/use-menus-content"

export default function HomeBar() {
  const menus = useMenusContent()

  return (
    <div
      id={"bar"}
      className={"bg-center bg-black relative bg-cover flex"}
      style={{ backgroundImage: `url(${barBg})` }}
    >
      <div className={"flex-1"}>&nbsp;</div>
      <div className={"flex-1"}>
        <div
          className={
            "flex-col items-center flex py-12 md:py-80 max-w-3xl gap-6"
          }
        >
          <div>
            <h2 className={"text-white text-4xl uppercase"}>Bar</h2>
          </div>
          <div>
            <Button
              target={"_blank"}
              theme={"light"}
              hoverTheme={"dark"}
              href={menus.drinkMenuPdf}
            >
              View Drinks Menu
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
