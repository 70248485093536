import * as React from "react"
import Container from "../container"
import banquetBg from "../../images/bg-group-bookings.jpg"
import Button from "../button"
import useMenusContent from "../../hooks/use-menus-content"

export default function HomeGroupBookings() {
  const menus = useMenusContent()

  return (
    <div id={"group-bookings"} className={"flex flex-col-reverse md:flex-row"}>
      <div className={"flex-1 bg-black items-center"}>
        <Container className={"flex-col py-12 md:py-64 gap-6 max-w-2xl"}>
          <div>
            <h2 className={"text-white text-4xl uppercase"}>Group Bookings</h2>
          </div>
          <div>
            <div className={"prose text-white font-cursive"}>
              <p className={"font-sans uppercase text-xl"}>
                We welcome Group Bookings
              </p>
              <p>
                We can tailor the set menu to your dietary needs. Children aged
                7 to 12 years of age will dine together at half price. Please
                include both adults and children into the number of people you
                book. A MINIMUM of $100 deposit is required to secure your
                table.
              </p>
              <p>
                Confirmation of exact numbers to be received 24 hours prior to
                your booking date so we can allocate seating accordingly. If
                numbers change significantly or a “no show’ occurs, we reserve
                the right keep the deposit. This is to cover the expenses of
                turning other potential bookings away. Otherwise, this deposit
                amount will be deducted from your total bill at the end of the
                night. Please note we do not refund the deposit back onto your
                credit card or via cash.
              </p>

              <p>
                NO SPLIT BILLS, ONE ACCOUNT PER TABLE. YOU’RE WELCOME TO SPLIT
                BILL VIA CASH OR BANK TRANSFER AMONGST YOURSELVES, HOWEVER UPON
                PAYMENT ONLY ONE FORM OF PAYMENT CAN BE PROCESSED. No Amex or
                Diners Cards accepted.
              </p>
            </div>
          </div>
          <div>
            <Button
              target={"_blank"}
              theme={"primary"}
              hoverTheme={"dark"}
              href={menus.banquetMenuPdf}
            >
              View Banquet Menu
            </Button>
          </div>
        </Container>
      </div>
      <div
        className={"bg-center relative bg-cover md:flex-1 h-64 md:h-auto"}
        style={{ backgroundImage: `url(${banquetBg})` }}
      >
        &nbsp;
      </div>
    </div>
  )
}
