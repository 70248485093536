import * as React from "react"
import Container from "../container"
// import reservationsBg from "../../images/reservations-bg.jpg"
import bottle from "../../images/bottle.png"
import useHomePage from "../../hooks/use-home-page"

export default function HomeReservations() {
  const homePage = useHomePage()

  return (
    <div
      id={"reservations"}
      className={"bg-fixed bg-white relative"}
      // style={{ backgroundImage: `url(${reservationsBg})` }}
    >
      <Container className={"flex-col py-12 items-center gap-6"}>
        <div>
          <h2 className={"text-black text-4xl uppercase"}>Reservations</h2>
        </div>
        <div
          className={
            "flex w-full flex-wrap md:flex-nowrap flex-col-reverse gap-6 md:flex-row justify-between"
          }
        >
          <div>
            <div
              className={"prose font-cursive"}
              dangerouslySetInnerHTML={{
                __html: homePage.reservations.content,
              }}
            />

            <img alt={"bottle"} src={bottle} />
          </div>
          <div>
            <iframe
              className={"w-full border-none"}
              style={{ height: 850 }}
              src={homePage.reservations.url}
              title="CanTho Bookings"
            />
          </div>
        </div>
      </Container>
    </div>
  )
}
