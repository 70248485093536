import * as React from "react"
import Layout from "../components/layout"
import HomeHero from "../components/home-hero"
import HeaderBar from "../components/header-bar"
import HomeVideo from "../components/home-video"
import HomeMenu from "../components/home-menu"
import HomeGroupBookings from "../components/home-group-bookings"
import HomeBar from "../components/home-bar"
import HomeReservations from "../components/home-reservations"
import HomeVouchers from "../components/home-vouchers"

export default function IndexPage() {
  return (
    <Layout>
      <HomeHero />
      <HeaderBar />
      <HomeVideo />
      <HomeMenu />
      <HomeGroupBookings />
      <HomeBar />
      <HomeReservations />
      <HomeVouchers />
    </Layout>
  )
}
