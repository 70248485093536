import * as React from "react"
import logo from "../../images/logo.png"
import arrow from "../../images/arrow.png"
import openingHours from "../../images/opening-hours.png"
import SocialIcons from "../social-icons"
import Container from "../container"

export default function HomeHero() {
  return (
    <Container
      className={"justify-between items-center p-6 md:h-screen flex-col gap-12"}
    >
      <div>
        <div
          className={
            "lg:absolute pb-6 lg:pb-0 top-0 right-6 md:right-16 hidden lg:block"
          }
        >
          <img
            className={"mx-auto"}
            src={openingHours}
            alt={"Store opening hours"}
          />
        </div>
        <p
          className={"text-white text-center font-cursive text-xl lg:text-2xl"}
        >
          VINTAGE VIETNAM · COCKTAILS · SHARE PLATES
        </p>
      </div>
      <div className={"w-1/2 md:w-1/3 lg:w-auto"}>
        <img alt="Can Tho Kitchen logo" width={350} src={logo} />
      </div>
      <div>
        <p className={"text-xl text-center text-white font-cursive mb-3"}>
          <a href="https://www.google.com.au/maps?q=5/465+Oxley+Dr,+Runaway+Bay,+QLD&um=1&ie=UTF-8&sa=X&ei=_zcCVciqL8rn8AWP2IGgBw&ved=0CAcQ_AUoAQ">
            5/465 Oxley Dr, Runaway Bay, QLD
          </a>
          {" · "}
          <br className={"md:hidden"} />
          <a href="tel:0755290098">Ph: 07 5529 0098</a>
          {" · "}
        </p>
        <SocialIcons className={"mb-3"} />
        <p className={"text-center"}>
          <img
            className={"inline"}
            width={30}
            alt="Arrow pointing downwards"
            src={arrow}
          />
        </p>
      </div>
    </Container>
  )
}
