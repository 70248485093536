import * as React from "react"
import Container from "../container"
import menuBg from "../../images/bg-menu.jpg"
import menuFlag from "../../images/menu-flag.png"
import openingHours from "../../images/opening-hours.png"
import Button from "../button"
import useMenusContent from "../../hooks/use-menus-content"

export default function HomeMenu() {
  const menus = useMenusContent()

  return (
    <div id={"menu"} className={"flex flex-col md:flex-row"}>
      <div
        className={"relative bg-center bg-cover md:flex-1 h-64 md:h-auto"}
        style={{ backgroundImage: `url(${menuBg})` }}
      >
        <div className="lg:absolute lg:left-16 top-0 w-full lg:w-auto flex justify-around">
          <img src={menuFlag} className={"mx-auto"} alt={"menu flag"} />

          <img
            className={"mx-auto lg:hidden"}
            src={openingHours}
            alt={"Store opening hours"}
          />
        </div>
      </div>
      <div className={"flex-1 bg-black flex items-center"}>
        <Container className={"flex-col py-12 md:py-64 gap-6 max-w-2xl"}>
          <div>
            <h2 className={"text-white text-4xl uppercase"}>Menu</h2>
          </div>
          <div>
            <div className={"prose text-white font-cursive"}>
              <p>
                For Can Tho Kitchen Bar to survive as a business during these
                ever changing times and for you to have the best possible dining
                experience we kindly ask all guests to dine with our new a la
                carte menu.
              </p>
              <p>
                Please be patient and allow our wonderful staff prepare and
                serve you during the easing-stage over the following months.
                This will ensure enough time to adhere to sanitising
                requirements put in place by the Government for everyone’s
                safety.
              </p>
            </div>
          </div>
          <div>
            <Button
              target={"_blank"}
              theme={"light"}
              hoverTheme={"dark"}
              href={menus.dinnerMenuPdf}
            >
              View Menu
            </Button>
          </div>
        </Container>
      </div>
    </div>
  )
}
