import * as React from "react"
import storePreview from "../../video/store-preview.webm"
import storePreviewMp4 from "../../video/store-preview.mp4"
import storePreviewThumbnail from "../../video/store-preview.jpg"

export default function HomeVideo() {
  return (
    <div className={"bg-white hidden md:block"} id={"about"}>
      <video muted autoPlay className={"w-full"} poster={storePreviewThumbnail}>
        <source src={storePreview} type="video/webm" />
        <source src={storePreviewMp4} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  )
}
