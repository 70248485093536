import { useStaticQuery, graphql } from "gatsby"

export interface MenuContent {
  dinnerMenuPdf: string
  drinkMenuPdf: string
  banquetMenuPdf: string
}

export default function useMenusContent(): MenuContent {
  const { menusYaml } = useStaticQuery(
    graphql`
      query {
        menusYaml {
          dinnerMenuPdf: dinner_menu_pdf
          drinkMenuPdf: drink_menu_pdf
          banquetMenuPdf: banquet_menu_pdf
        }
      }
    `
  )
  return menusYaml
}
