import * as React from "react"
import {
  FaEnvelope,
  FaFacebookF,
  FaInstagram,
  FaPhone,
  FaTwitter,
} from "react-icons/fa"
import SocialIcon from "../social-icon"

interface SocialIconsProps {
  className?: string
}

export default function SocialIcons({ className = "" }: SocialIconsProps) {
  const classNames = "text-white hover:text-yellow"

  return (
    <div className={`flex justify-center gap-4 ${className}`}>
      <SocialIcon
        className={classNames}
        href={"tel:0755290098"}
        icon={<FaPhone />}
        title={"Call Can Tho Kitchen"}
      />
      <SocialIcon
        className={classNames}
        href={"mailto:meetyou@canthokitchen.com.au"}
        icon={<FaEnvelope />}
        title={"Email Can Tho Kitchen"}
      />
      <SocialIcon
        className={classNames}
        href={"https://instagram.com/canthokitchenbar/"}
        icon={<FaInstagram />}
        title={"Visit Can Tho Kitchen's Instagram"}
      />
      <SocialIcon
        className={classNames}
        href={"https://www.facebook.com/CanThoKitchen"}
        icon={<FaFacebookF />}
        title={"Visit Can Tho Kitchen's Facebook"}
      />
      <SocialIcon
        className={classNames}
        href={"https://twitter.com/canthokitchen"}
        icon={<FaTwitter />}
        title={"Visit Can Tho Kitchen's Twitter"}
      />
    </div>
  )
}
