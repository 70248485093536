import { useStaticQuery, graphql } from "gatsby"

export interface HomePage {
  reservations: {
    url: string
    content: string
  }
}

export default function useHomePage(): HomePage {
  const { pagesYaml } = useStaticQuery(
    graphql`
      query {
        pagesYaml(
          parent: {
            internal: {
              description: { regex: "/.*site/content/pages/home.*/" }
            }
          }
        ) {
          reservations {
            url
            content
          }
        }
      }
    `
  )
  return pagesYaml
}
